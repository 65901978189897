import React, { Component } from 'react';
import Link from '../../../../core/Link';

import './index.css';

import bookingEngineIcon from './images/glyph_booking_engine.svg';
import propertyManagementSystemIcon from './images/glyph_pms.svg';
import channelManagerIcon from './images/glyph_channel_mgr.svg';
import integrationsIcon from './images/glyph_integrations.svg';
import paymentProcessingIcon from './images/glyph_payment_processing.svg';

export class FeaturePanel extends Component {
  render() {
    return (
      <div className="component product-panel">
        <div className="product-panel-inner-wrapper">
          <div className="product booking-engine">
            <div className="product-icon">
              <img src={bookingEngineIcon} alt="" />
            </div>
            <div className="product-content">
              <h2>Booking Engine</h2>
              <div className="product-description">
                {this.props.bookingEngine}
              </div>
              <div className="product-learn-more">
                <Link
                  to="/products/booking-engine"
                  aria-label="Learn more about our booking engine"
                >
                  Learn more...
                </Link>
              </div>
            </div>
          </div>

          <div className="product property-management-system">
            <div className="product-icon">
              <img src={propertyManagementSystemIcon} alt="" />
            </div>
            <div className="product-content">
              <h2>Property Management System</h2>
              <div className="product-description">
                {this.props.propertyManagement}
              </div>
              <div className="product-learn-more">
                <Link
                  to="/products/property-management-system"
                  aria-label="Learn more about our property management system"
                >
                  Learn more...
                </Link>
              </div>
            </div>
          </div>

          <div className="product channel-manager">
            <div className="product-icon">
              <img src={channelManagerIcon} alt="" />
            </div>
            <div className="product-content">
              <h2>Channel Manager</h2>
              <div className="product-description">
                {this.props.channelManager}
              </div>
              <div className="product-learn-more">
                <Link
                  to="/products/channel-manager"
                  aria-label="Learn more about our channel manager"
                >
                  Learn more...
                </Link>
              </div>
            </div>
          </div>

          <div className="product payment-processing">
            <div className="product-icon">
              <img src={paymentProcessingIcon} alt="" />
            </div>
            <div className="product-content">
              <h2>Payment Processing</h2>
              <div className="product-description">
                {this.props.paymentProcessing}
              </div>
              <div className="product-learn-more">
                <Link
                  to="/products/payment-processing"
                  aria-label="Learn more about our payment processing"
                >
                  Learn more...
                </Link>
              </div>
            </div>
          </div>

          <div className="product integrations">
            <div className="product-icon">
              <img src={integrationsIcon} alt="" />
            </div>
            <div className="product-content">
              <h2>Integrations</h2>
              <div className="product-description">
                {this.props.integrations}
              </div>
              <div className="product-learn-more">
                <Link
                  to="/products/integrations"
                  aria-label="Learn more about our integrations"
                >
                  Learn more...
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FeaturePanel;
