import React, { Component } from 'react';
import Helmet from 'react-helmet';

import SolutionsPage from '../components/SolutionsPage';
import Banner from '../../../core/Banner';
import Panel from '../../../core/Panel';
import Heading from '../../../core/Heading';
import Columns from '../../../core/Columns';
import Column from '../../../core/Column';
import Paragraph from '../../../core/Paragraph';

import FeaturePanel from '../components/FeaturePanel';

export class RvCampgroundsPage extends Component {
  render() {
    return (
      <SolutionsPage>
        <Helmet title="RV Park &amp; Campground Management Software – ThinkReservations">
          <meta
            name="description"
            content="With RV campground management software from ThinkReservations, your booking and payment processing could be easier than ever. Click here to learn more."
          />
          <script
            type="application/ld+json"
            children={JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Service',
              serviceType: 'RV Campground Management Software',
              category:
                'Reservation Management Software for Small and Boutique Hotels',
              provider: {
                '@type': 'Organization',
                name: 'ThinkReservations',
              },
              areaServed: {
                '@type': 'Country',
                name: 'USA',
              },
            })}
          />
        </Helmet>

        <Banner
          subheader="RV Campground Management Software"
          content="Powerful RV campground software from ThinkReservations makes it easier to book reservations, process payments, and manage your property."
        />

        <Panel id="panel-multitude-of-features" backgroundColor="#f5f3f3">
          <Heading size="large" level={2}>
            A Better System for You
          </Heading>
          <Paragraph
            style={{
              fontWeight: '300',
              textAlign: 'center',
              margin: '0 auto',
            }}
          >
            When you integrate our RV park software platform into your
            operations, you should see immediate benefits for you and your
            guests. Our solutions are designed to save you time and enhance your
            revenue by making your essential processes as seamless and simple as
            possible. We take a lot of the work off your shoulders and allow you
            to focus on providing the best experience to your guests. Here’s a
            quick summary of the many benefits we provide:
          </Paragraph>
          <FeaturePanel
            bookingEngine="Give your guests an intuitive and easy online booking experience and increase
            your direct reservations. Grow revenue by making it possible for guests to add packages and
            services to their stay. Our RV campground booking engine can integrate into your existing
            website easily, featuring a mobile-responsive and ADA accessible design."
            propertyManagement="You need clear, accessible data about your business operations,
            so you can make the most informed decisions for you and your guests. When you have our RV
            management software at your disposal, you gain comprehensive tools for handling practically
            every aspect of your business. From calculating your taxes, quickly managing rates, and
            managing guest information, you’ll have a bird’s eye view of where you stand and how you can
            grow."
            channelManager="This system syncs in real time with the top booking sites including
            Booking.com and Airbnb. This means you can instantly and automatically update room
            availability, nightly rates, and restrictions across all of these platforms simultaneously. Avoid
            double bookings and increase your revenue by taking advantage of our automated channel
            management."
            paymentProcessing="We have direct connections with many of the leading credit card
            processors in the lodging industry. This means when you choose our campground reservation
            software, we can connect seamlessly and simplify your payment processing."
            integrations="We offer the ability to link to a wide range of valuable systems that can help you
            make the most of your campground properties. These tools can enhance the guest experience
            and make your business more profitable and successful."
          />
        </Panel>

        <Panel id="panel-better-experience">
          <Columns>
            <Column>
              <Heading size="large" level={2}>
                Get the Edge You Need
              </Heading>
              <Paragraph style={{ fontWeight: '300', margin: '0 0 25px 0' }}>
                One of the most important benefits that our solutions provide
                for RV campground owners and operators is how they can increase
                the number of direct online bookings. Our systems also give you
                the power to upsell packages and other items to maximize your
                success and stand out from your competitors. Plus, you’ll gain
                access to 24/7 support to assist you whenever you need it.
              </Paragraph>
              <Paragraph style={{ fontWeight: '300' }}>
                This is the best way to make your operations run smoother with
                fewer headaches. It’s easy to get started, so why not enjoy the
                benefits as soon as possible?
              </Paragraph>
            </Column>
          </Columns>
        </Panel>
      </SolutionsPage>
    );
  }
}

export default RvCampgroundsPage;
