import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.css';

export class Paragraph extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { id, className, style } = this.props;

    const componentStyles = {
      ...style,
    };

    return (
      <div
        className={`component paragraph ${className}`}
        style={componentStyles}
        id={id}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Paragraph;
