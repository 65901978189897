import React, { Component } from 'react';

import App from '../../../../layout/App';

import './index.css';

export class SolutionsPage extends Component {
  static propTypes = {};

  render() {
    return (
      <App>
        <div className="component solutions-page">{this.props.children}</div>
      </App>
    );
  }
}

export default SolutionsPage;
